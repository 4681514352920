$transitionFast: 125ms all cubic-bezier(0.230, 1.000, 0.320, 1.000);
$transition: 500ms all cubic-bezier(0.230, 1.000, 0.320, 1.000);
$transitionSlow: 750ms all cubic-bezier(0.230, 1.000, 0.320, 1.000);

$glowplug-primary: #7d5fff;
$glowplug-secondary:#C9D6DF;
$glowplug-success: #55efc4;
$glowplug-info: #e84393;
$glowplug-danger: #ff3838;
$glowplug-warning: #ff9f1a;
$glowplug-light:#fcfcfd;
$glowplug-dark:#4e4e4e;
$glowplug-gold: #f1c40f;
// $glowplug-background: #f6f7f9;
$glowplug-background: transparent;
$glowplug-border: #c3cfdd;
$glowplug-white: #ffffff;

$padding-container: 0.5rem;

$glowplug-border-radius: 1rem;
$glowplug-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
$glowplug-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$glowplug-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);

$navOffset: 20px;
$navHeight: 100px;
$navToggleOffset: 70px;
$hero-gradient-bg: linear-gradient(45deg, $glowplug-primary 0%, lighten($glowplug-primary, 5%) 100%);
