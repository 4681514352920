@import "./assets/scss/vars.scss";

$theme-colors: (
  primary: $glowplug-primary,
  secondary: $glowplug-secondary,
  success: $glowplug-success,
  info: $glowplug-info,
  warning: $glowplug-warning,
  danger: $glowplug-danger,
  light: $glowplug-light,
  dark: $glowplug-dark,
  border: $glowplug-border,
  reward: $glowplug-gold,
);

$link-color: darken($glowplug-primary, 15%);
$link-color-hover: $glowplug-primary;

$dropdown-link-active-bg: $glowplug-primary;
$dropdown-link-hover-bg: $glowplug-primary;
$dropdown-link-hover-color: $glowplug-white;
// $dropdown-link-color: glowplug-white;
$nav-pills-link-active-bg: $glowplug-primary;
$nav-pills-link-active-color: $glowplug-white;
$nav-pills-link-hover-bg: lighten($glowplug-primary, 10%);

$border-radius: $glowplug-border-radius;
$border-radius-lg: $glowplug-border-radius;
$border-radius-sm: $glowplug-border-radius;

$card-border-color: $glowplug-border;

// $card-border-radius:$glowplug-border-radius;;

$enable-rounded: true;

$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1rem;
@import "./assets/scss/bootstrap/bootstrap.scss";

body,
html,
#root {
  // height: 100%;
}

body {
  background-color: $glowplug-background;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
}
.admin-col {
  width: 300px;
}
.admin-bar {
  height: 2px;
}
.admin-card-header {
  font-size: 0.9rem;
}

.twitter-tweet.twitter-tweet-rendered {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.bg-image {
  width: 400px;
  max-width: 100%;
  display: flex;
  margin: 5rem auto;
}

.pricing-table,
.table-responsive {
  border-radius: $border-radius;
  background-color: $white;
  max-height: calc(100vh - 156px);
  overflow: auto;
}

.pricing-table {
  max-height: none;
}

.expandablePane {
  $height: calc(100vh - #{2 * $navOffset});
  $right: $navOffset;
  background-color: white;
  border-radius: $glowplug-border-radius;
  border: 1px solid $glowplug-border;
  height: $height;
  position: fixed;
  right: -400px;
  top: $navOffset;
  transition: $transitionSlow;
  width: 400px;
  z-index: 9;
  max-width: calc(100% - #{2 * $navOffset});
  &.from-left {
    right: auto;
    left: -400px;

    &.wide {
      left: -600px;
    }

    &.open {
      left: $right;
    }
  }

  &.wide {
    width: 600px;
    right: -600px;
  }
  &.open {
    box-shadow: $glowplug-box-shadow-lg;
    right: $right;
    transition: $transition;
  }
  .expandable-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    max-height: 100px;
    margin-bottom: 1rem;
  }
  .expandable-content {
    max-height: calc(#{$height} - 100px);
    overflow: auto;
  }
}
.nav-pills .nav-link:not(.active):hover {
  background-color: lighten($glowplug-primary, 25%);
  transition: $transition;
}

.nav-pills {
  &.light {
    .nav-link {
      color: white;
      &:not(.active):hover {
        color: $glowplug-primary;
      }
      &.active {
        background-color: $glowplug-light;
        color: $glowplug-primary;
      }
    }
  }
}

.hero {
  background: $glowplug-primary;
  background: $hero-gradient-bg;
  color: white;
  padding: 2rem;
  border-radius: $glowplug-border-radius;
  box-shadow: $glowplug-box-shadow;
}

.card {
  box-shadow: $glowplug-box-shadow;
  .card-body {
    border-bottom-left-radius: $glowplug-border-radius;
    border-bottom-right-radius: $glowplug-border-radius;
  }
  &.neu {
    z-index: -1;
    background-color: $glowplug-background;
    box-shadow: 14px 14px 33px #dddee0, -14px -14px 33px #ffffff;
    border-color: darken($glowplug-background, 3%);
  }
}

.gradient-text {
  // font-size: 100px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  &.text-primary {
    background-image: linear-gradient(
      90deg,
      $glowplug-primary,
      darken($glowplug-primary, 10%)
    );
  }
  &.text-success {
    background-image: linear-gradient(
      90deg,
      $glowplug-success,
      darken($glowplug-success, 10%)
    );
  }
  &.text-warning {
    background-image: linear-gradient(
      90deg,
      $glowplug-warning,
      darken($glowplug-warning, 10%)
    );
  }
  &.text-danger {
    background-image: linear-gradient(
      90deg,
      $glowplug-danger,
      darken($glowplug-danger, 10%)
    );
  }
  &.text-info {
    background-image: linear-gradient(
      90deg,
      $glowplug-info,
      darken($glowplug-info, 10%)
    );
  }
}

.settings-col {
  .settings-section {
    border-radius: $glowplug-border-radius;
    &:nth-of-type(2n) {
      background-color: white;
    }
  }
  &.invert {
    .settings-section {
      &:nth-of-type(2n) {
        background-color: $glowplug-background;
      }
    }
  }
}

.list-group {
  max-height: 200px;
  overflow: auto;
}

.settings-help-pane {
  position: sticky;
  top: 20px;

  .settings-help-content {
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: white;
    z-index: 999;
  }
}
.html-content {
  img {
    max-width: 100%;
    border-radius: $glowplug-border-radius;
  }
}

.settings-paragraph {
  line-height: 1.5;
}

.sticky-container {
  @media (min-width: 768px) {
    position: sticky;
    top: 20px;
    z-index: 9;
  }
}

.fb-post {
  min-height: 300px;
}

.fb_iframe_widget {
  max-width: 100% !important;
  min-width: 100% !important;
  // span {
  //   width: 100% !important;
  //   height: 100% !important;
  // }
  // div {
  //   span {
  //     width: 100% !important;
  //   }
  // }
}

.rangeslider {
  box-shadow: none !important;
}

.rangeslider-horizontal {
  width: 100%;
}
.rangeslider-horizontal .rangeslider__fill {
  width: 100%;
  background-color: $glowplug-primary !important;
  box-shadow: none !important;
}

.rangeslider .rangeslider__handle {
  background-color: #c9d6df !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: none !important;
}
