@import '../../assets/scss/vars.scss';

.wrapper {
  position: fixed;
  right:-200px;
  top: $navOffset;
  z-index: 99;
  color: $glowplug-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transitionSlow;

  &.isLoading {
    right: $navOffset;
    transition: $transitionSlow;
  }
}